var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('search-box',{attrs:{"label":"* Agrupamento","popup-label":"Selecione um Agrupamento","id":_vm.value.idGrupo,"descricao":_vm.value.descricaoGrupo,"hidden-clear":true,"value":_vm.grupos,"loader-fn":_vm.loaderGrupos,"item-key":"id","item-text":"descricao","rules":[
            this.$validators.notNullOrUndefined
        ]},on:{"update:id":function($event){return _vm.$set(_vm.value, "idGrupo", $event)},"update:descricao":function($event){return _vm.$set(_vm.value, "descricaoGrupo", $event)}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('p',{staticClass:"ma-2"},[_vm._v(" Nenhum Agrupamento encontrado ")])]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-text-field',{attrs:{"label":"* Descricao","dense":"","rules":[
                      this.$validators.string.required,
                        function (v) { return this$1.$validators.string.greaterThanOrEquals(v, 1); },
                        function (v) { return this$1.$validators.string.lessThanOrEquals(v, 200); }
                      ]},model:{value:(_vm.value.descricao),callback:function ($$v) {_vm.$set(_vm.value, "descricao", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.descricao"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-radio-group',{attrs:{"row":"","dense":""},model:{value:(this.value.flgValor),callback:function ($$v) {_vm.$set(this.value, "flgValor", $$v)},expression:"this.value.flgValor"}},[_c('v-radio',{attrs:{"label":"Não Modifica valor","value":"N"}}),_c('v-radio',{attrs:{"label":"Sim, Modifica valor","value":"S"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }