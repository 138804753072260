<template>
<v-row>
  <v-col cols="12" md="12">
    <search-box
        label="* Agrupamento"
        popup-label="Selecione um Agrupamento"
        :id.sync="value.idGrupo"
        :descricao.sync="value.descricaoGrupo"
        :hidden-clear="true"
        :value="grupos"
        :loader-fn="loaderGrupos"
        item-key="id"
        item-text="descricao"
        :rules="[
            this.$validators.notNullOrUndefined
        ]">
      <template v-slot:empty>
        <p class="ma-2">
          Nenhum Agrupamento encontrado
        </p>
      </template>
    </search-box>
  </v-col>
  <v-col cols="12" md="12">
    <v-text-field label="* Descricao"
                  dense
                  :rules="[
                      this.$validators.string.required,
                        v => this.$validators.string.greaterThanOrEquals(v, 1),
                        v => this.$validators.string.lessThanOrEquals(v, 200)
                      ]"
                  v-model.trim="value.descricao"/>
  </v-col>
  <v-col cols="12" sm="12" >
    <v-radio-group v-model="this.value.flgValor" row dense>
      <v-radio
        label="Não Modifica valor"
        value="N"
      ></v-radio>
      <v-radio
        label="Sim, Modifica valor"
        value="S"
      ></v-radio>
    </v-radio-group>
  </v-col>
</v-row>
</template>

<script>
import SearchBox from "../common/SearchBox";
import {findAll as findAllGrupos} from "../../../api/grupoTipoModificacao";

export default {
  name: "TipoModificacaoForm",
  components: {SearchBox},
  props: {
    value: {
      type: Object
    }
  },
  data () {
    return {
      checkBoxFlagValor: false,
    }
  },
  computed: {
    grupos() {
      if (this.value.idGrupo == null) return null;
      return {
        id: this.value.idGrupo,
        descricao: this.value.descricaoGrupo
      }
    },
  },
  methods: {
    loaderGrupos(page, search) {
      return findAllGrupos(page, {
        nome: search
      })
    },
  },
}
</script>

<style scoped>

</style>